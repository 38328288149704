import React, { useEffect, useState } from 'react';
import ScreenshotBgImg from '../images/screenshot-bg.png';
import FeaturedCircle from '../images/feature-circle.png';
import FeaturedCircle2 from '../images/feature-circle-two.png';
import MainBannerImg1 from '../images/main-banner1.png';
import OpenBookImg from '../images/open-book.png';

const ScreenShot = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      document.querySelector('.loader-wrapper').style = 'display: none';
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${ScreenshotBgImg})`,
  });

  useEffect(() => {
    setBgImg({
      backgroundImage: `url(${ScreenshotBgImg})`,
    });
  }, []);

  // const options = {
  //   arrows: false,
  //   accessibility: false,
  //   infinite: true,
  //   dots: false,
  //   margin: 30,
  //   speed: 300,
  //   autoplaySpeed: 8000,
  //   slidesToShow: 5,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1200,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         centerMode: true,
  //         centerPadding: '110px',
  //       },
  //     },
  //     {
  //       breakpoint: 991,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         centerMode: true,
  //         centerPadding: '0',
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         centerMode: true,
  //         centerPadding: '132px',
  //       },
  //     },
  //     {
  //       breakpoint: 576,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         centerMode: true,
  //         centerPadding: '132px',
  //       },
  //     },
  //     {
  //       breakpoint: 420,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         centerMode: true,
  //         centerPadding: '50px',
  //       },
  //     },
  //   ],
  // };

  return (
    <section id="screenshot" className="screenshot padding-top-bottom" style={bgImg}>
      <div className="screenshot-decor">
        <div className="screenshot-circle1">
          <img src={FeaturedCircle2} alt="feature-circle-two" />
        </div>
        <div className="screenshot-circle2">
          <img src={FeaturedCircle} alt="feature-circle" />
        </div>
        <div className="screenshot-circle3">
          <img src={MainBannerImg1} alt="feature-circle-three" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 ">
            <div className="screenshot-contain">
              <img className="mob-mocup img-fluid" src={OpenBookImg} alt="screenshot-mob" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScreenShot;
