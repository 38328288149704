import React, { useEffect, useState } from 'react';
import FeatureBgImg from '../images/feature-bg.png';
import FeatureCircleImg from '../images/feature2.png';
import FeatureProductImg from '../images/book-feature.png';
import IconImg1 from '../images/icon/1.png';
import IconImg3 from '../images/icon/3.png';
import IconImg2 from '../images/icon3.png';
import IconImg5 from '../images/icon/5.png';
import IconImg4 from '../images/icon/4.png';
import IconImg6 from '../images/icon/6.png';

const Feature = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      document.querySelector('.loader-wrapper').style = 'display: none';
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${FeatureBgImg})`,
  });

  useEffect(() => {
    setBgImg({
      backgroundImage: `url(${FeatureBgImg})`,
    });
  }, []);

  return (
    <section id="feature" className="feature" style={bgImg}>
      <div className="feature-decor">
        <div className="feature-circle1">
          <img src={FeatureCircleImg} alt="" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="feature-phone">
            <img src={FeatureProductImg} className="img-fluid" alt="" />
          </div>
          <div className="offset-lg-4 col-lg-8">
            <div className="row">
              <div className="col-sm-12 mrgn-md-top">
                <h2 className="title f-bold">Co zawiera publikacja</h2>
              </div>
              <div className="col-12 col-md-6">
                <ul className="feature-style">
                  <li>
                    <div className="feature-icon">
                      <img src={IconImg1} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                      <h3>Wysoka jakość wykonania</h3>
                    </div>
                    <div>
                      <p>
                        Skład, druk, dobór papieru oraz twarda okładka – to wszystko składa się
                        idealnie w jedną całość.{' '}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="feature-icon">
                      <img src={IconImg3} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                      <h3>Grafiki</h3>
                    </div>
                    <div>
                      <p>
                        Profesjonalne wykonanie grafik, obróbki zdjęć oraz wykresów w sposób
                        czytelny oddają to, co autor chciał przekazać.{' '}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="feature-icon">
                      <img src={IconImg5} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                      <h3>Merytoryka</h3>
                    </div>
                    <div>
                      <p>
                        Treść w 100% oparta na badaniach naukowych oraz przodujących książkach z
                        anatomii, biomechaniki i treningu siłowego. Treść sprawdzona przez
                        utytuowane i niezależne osoby trzecie.{' '}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-6 sm-m-top">
                <ul className="feature-style">
                  <li>
                    <div className="feature-icon">
                      <img src={IconImg2} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                      <h3>Prosty język</h3>
                    </div>
                    <div>
                      <p>
                        Ponad 4 lata pracy, pisania, opracowywania badań, tłumaczenia, kupowania
                        książek oraz dostępu do badań. To wszystko skompresowane do blisko 400 stron
                        napisanych przystępnym językiem.{' '}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="feature-icon">
                      <img src={IconImg4} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                      <h3>Kompleksowość</h3>
                    </div>
                    <div>
                      <p>
                        Anatomia, biomechanika i trening siłowy z naciskiem na trójbój siłowy, czyli
                        jak podnosić dużo, zdrowo i pod Twoje ciało. Nie zabraknie elementów z
                        plyometrii, biegu, balistyki i ruchów dnia codziennego.{' '}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="feature-icon">
                      <img src={IconImg6} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                      <h3>Zrozumienie</h3>
                    </div>
                    <div>
                      <p>
                        Czym są siły, jak działają na nasze ciało, jak możesz przełożyć wiedzę
                        dotyczącą sił na lepszą wydajność w sporcie oraz efektywność danego ruchu.{' '}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;
