import '../App.css';
import React, { useEffect } from 'react';
import Home from './Home';

function App() {
  useEffect(() => {
    const timer = setTimeout(() => {
      document.querySelector('.loader-wrapper').style = 'display: none';
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
