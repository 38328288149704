import React from 'react';
import './index.css';
import App from '../components/App';
import './index.scss';

const Main = () => {
  // const { title, lang, description } = headData;

  return (
    <>
      {/*<Helmet>*/}
      {/*    <meta charSet="utf-8" />*/}
      {/*    <title>{title || 'Gatsby Simplefolio'}</title>*/}
      {/*    <html lang={lang || 'en'} />*/}
      {/*    <meta name="description" content={description || 'Gatsby Simplefolio'} />*/}
      {/*</Helmet>*/}
      <div className="loader-wrapper">
        <div className="loader" />
      </div>
      <App />
    </>
  );
};
export default Main;
